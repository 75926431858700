.header
{
	padding: 30px 0 0;
	position: relative;
	margin-bottom: 38px;
	background: #fff;
	z-index: 50;
	&:after
	{
		content: ' ';
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 38px;
		background: url(../img/header-shadow.png) repeat-x center bottom; 
	}
	.container
	{
		//background: #ccc;
		&:last-child
		{
			margin-bottom: 0;
		}
	}
	&__logo
	{
		@include span-columns(4);
		img
		{
			width: 100%;
			max-width: 300px;
		}
	}
	&__main
	{
		text-align: center;
		@include span-columns(4);
	}
	&__social
	{
		cursor: default;
		a
		{
			display: inline-block;
			width: 46px;
			height: 46px;
			background: $brand-slave;
			line-height: 46px;
			text-align: center;
			font-size: 30px;
			border-radius: 50%;
			color: #fff;
			margin: 0 0.8vw;
			@include transition;
			&:hover
			{
				background: $brand-primary;
			}
		}
	}
	&__links
	{
		margin-top: $space-y*2;
		.btn
		{
			text-transform: none;
			padding: 5px 14px;
			margin: 0 6px;
		}
	}
	&__contacts
	{
		text-align: right;
		@include span-columns(4);
		&__phone
		{
			color: $brand-primary;
			//font-family: $font-regular;
			text-decoration: none;
			display: block;
			font-size: 28px;
			line-height: 1;
			margin-bottom: $space-y/2;
		}
		&__mail
		{
			margin-bottom: $space-y/1.5;
			font-size: 22px;
			display: block;
			color: $brand-slave;
			text-decoration: none;
		}
		.btn
		{
			font-family: $font-regular;
		}
	}
	&__nav
	{
		text-align: center;
		margin-top: $space-y*2.5;
		& > ul
		{
			margin: 0;
			padding: 0;
			list-style: none;
			cursor: default;
			ul
			{
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 100%;
				padding: 10px 0;
				left: 0;
				width: 100%;
				background: #fff;
				list-style: none;
				z-index: 100;
				-webkit-box-shadow: 0 5px 5px 0 rgba(0,0,0,.2);
				box-shadow: 0 5px 5px 0 rgba(0,0,0,.2);
				@include transition;
				.active
				{
					a
					{
						color: $brand-primary;
						font-weight: bold;
					}
				}
				a
				{
					font-size: 12px;
					line-height: 1.2;
					padding: 5px;
					&:hover
					{
						color: $brand-primary;
					}
				}
			}
			& > li
			{
				display: inline-block;
				position: relative;
				&:hover
				{
					ul
					{
						opacity: 1;
						visibility: visible;
					}
					& > a
					{
						color: $brand-primary;
					}
				}
				&.active
				{
					& > a
					{
						border-color: $brand-primary;	
						color: $brand-primary;

					}
					
				}
			}
			a
			{
				font-size: 15px;
				text-transform: uppercase;
				color: #888787;
				text-decoration: none;
				padding: 17px 20px 15px;
				border: 1px solid transparent;
				border-bottom: 0;
				text-shadow: rgba(0,0,0,.01) 0 0 1px;
				display: block;
				@include transition;
				&:hover
				{
					
				}
			}
		}
	}
}

