.map
{
	width: 100%;
	height: 400px;
	margin-top: gutter();
	margin-bottom: gutter();
	border: 2px solid $gray;
	background: #fff url(../img/ajax-loader.gif) no-repeat center center;
	&.initialized
	{
		background-image: none;
	}
}

.map--hidden
{
	display: none;
}

.map__title
{
	color: $brand-primary;
    font-size: 17px;
    margin: 0;
}

.map__description,
.map__address
{
	margin: 0;
    font-size: 15px;
    color: lighten($gray-dark, 50%); 
}

.map__content
{
	margin-top: 20px;
	table
	{
		margin: 0;
	}
}
