

@mixin bp($breakpoint-name)
{
    @media only screen and (max-width: map-get($grid-breakpoints, $breakpoint-name)) {
      @content;
    }
}

@mixin transition($time: 0.15s, $ease: ease) {
  $type: all;
  -webkit-transition: $type $time $ease;
  -moz-transition: $type $time $ease;
  -ms-transition: $type $time $ease;
  -o-transition: $type $time $ease;
  transition: $type $time $ease;
}

@mixin block-height($height: 100%)
{
    position: relative;
    overflow: hidden;
    &::after
    {
      content: '';
      display: block;
      margin-top: $height; 
    }
}

@mixin translate($x)
{
  -moz-transform: translateX($x);
  -webkit-transform: translateX($x);
  -o-transform: translateX($x);
  -ms-transform: translateX($x);
  transform: translateX($x);
}