.table-responsive
{
  overflow-y: hidden;
  width: 100%;
  table
  {
    min-width: 500px;
  }
}

table:not([class]),
.table
{
    width: 100%;
    text-align: left;
    caption
    {
      font-style: italic;
      color: $gray-dark;
      text-align: left;
    }
    th
    {
      background: $gray;
    }
    th,
    td
    {
      padding: $space-y/2 $space-y;
    }
    tr
    {
        &:nth-child(even)
        {
          td
          {
            background: $gray-light;
          }
        }
    }
}