.footer
{
	background: #000;
	color: #fafafa;
	padding: $space-y 30px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	
	-moz-transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);

	.container
	{
		margin: 0;
	}
	&__copyrights
	{
		float: left;
		width: 300px;
		padding-top: 10px;
		font-size: 13px;
	}
	&__nav
	{
		margin-left: 330px;
		margin-right: 330px;
		text-align: center;
		padding-top: 10px;
		ul
		{
			list-style: none;
			padding: 0;
			cursor: default;
			margin: 0;
			li
			{
				display: inline-block;
				margin: 0 5px;
				a
				{
					color: #ddd;
					display: block;
					font-size: 9px;
					text-transform: uppercase;
					text-decoration: none;
					//font-weight: bold;
					@include transition;
					font-family: $font-regular;
					&:hover
					{
						color: #fff;
					}
				}
			}
		}
	}
	&__social
	{
		width: 300px;
		float: right;
		text-align: right;
		cursor: default;
		button
		{
			vertical-align: middle;
			background: none;
			border: 0;
			padding: 0;
			color: $brand-primary;
			margin-left: 20px;
			text-transform: uppercase;
			font-size: 14px;
			font-family: $font-regular;
		}
		a
		{
			display: inline-block;
			vertical-align: middle;
			width: 40px;
			height: 40px;
			background: $brand-primary;
			line-height: 40px;
			text-align: center;
			font-size: 26px;
			border-radius: 50%;
			color: #fff;
			margin: 0 5px;
			@include transition;
			&:hover
			{
				background: darken($brand-primary, 10%);
			}
		}
	}
}