body
{
	position: relative;
	&.splashed
	{
		&:after
		{
			content: ' ';
			width: 282px;
			height: 737px;
			background: url(../img/right-splash.jpg) no-repeat center;
			position: absolute;
			right: 0;
			top: 80vh;
			z-index: -1;
		}
		&:before
		{
			content: ' ';
			width: 50px;
			height: 90px;
			background: url(../img/left-splash.jpg) no-repeat center;
			position: absolute;
			left: 0;
			top: 80vh;
		}	
	}
}

main a
{
	color: $brand-primary;
}

.popup
{
	max-width: 450px;
	margin: 0 auto;
	position: relative;
	h3
	{
		font-family: $font-body;
		text-transform: uppercase;
		color: #fff;
		font-weight: 100;
		margin-bottom: 15px;
	}
	&__inner
	{
		background: #fff;
		padding: 35px 25px;
		border-radius: 5px;
	}
	.form-control
	{
		border-color: transparent;
		background: #e5eaf0 !important; 
		margin-bottom: 25px;
	}
	.mfp-close
	{
		width: 22px;
		height: 22px;
		background: none !important; 
		right: 0 !important;
		top: 2px !important;
	}
}

h1
{
	text-align: center;
	font-family: $font-body;
	font-size: 32px;
	font-weight: 100;
	margin-top: $space-y*4;
	text-transform: uppercase;
	b
	{
		color: $brand-primary;
		font-family: $font-heading;
	}
}

main
{
	//
	padding-bottom: 500px;
	position: relative;
	&:before
	{
		background: url(../img/footer-splash.jpg) no-repeat center bottom;
		content: ' ';
		display: block;
		width: 317px;
		height: 140px;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		margin-bottom: 200px;
	}
}

.icon
{
	display: inline-block;
}

.sq
{
	text-align: center;
	padding: $space-y*5 0;
	position: relative;
	&:before
	{
		content: ' ';
		display: block;
		position: absolute;
		top: -200px;
		left: 0;
		right: 0;
		bottom: -160px;
		background: url(../img/splash.jpg) no-repeat center top;
		background-size: auto 100%;
	}
	&__item
	{
		margin: 0 $space-y*2.5;
		display: inline-block;
		width: 40%;
		max-width: 487px;
		position: relative;
		line-height: 1px;
		img
		{
			width: 100%;
			z-index: 5;
			position: relative;
		}
	}
	&__inner
	{
		@include position(absolute, 0 0 0 0);
		z-index: 10;
		font-size: $font-size-default;
		line-height: 1.2;
		padding: 15% 5%;
		.btn
		{
			font-family: $font-regular;
			text-align: left;
		}
	}
}

.catalogs
{
	position: relative;
	z-index: 10;
	text-align: center;
	margin-top: $space-y*2;
	&__list
	{
		margin-top: $space-y*5;

	}
	&__title
	{
		display: inline-block;
		width: 100%;
		line-height: 1.3;
		color: rgba(255,255,255,.8);
		font-family: $font-regular; 
		font-weight: bold;
		font-size: 24px;
	}
	&__link
	{
		@include position(absolute, 0 0 0 0);
		z-index: 10;
		&:hover
		{
			& + .catalogs__inner
			{
				&:before
				{
					-moz-transform: scale(1) translateZ(0);
					-webkit-transform: scale(1) translateZ(0);
					-o-transform: scale(1) translateZ(0);
					-ms-transform: scale(1) translateZ(0);
					transform: scale(1) translateZ(0);
				}
				
				.btn
				{
					opacity: 1;
				}

				.catalogs__title
				{
					color: #fff;
				}

				& + img
				{
					-moz-transform: scale(1.1) translateZ(0);
					-webkit-transform: scale(1.1) translateZ(0);
					-o-transform: scale(1.1) translateZ(0);
					-ms-transform: scale(1.1) translateZ(0);
					transform: scale(1.1) translateZ(0);
				}
			}
		}
	}
	&__item
	{
		margin: 0 2.5%;
		display: inline-block;
		width: 28%;
		max-width: 341px;
		position: relative;
		line-height: 1px;
		overflow: hidden;
		border-radius: 50%;
		 -webkit-transform: translateZ(0);
    	-moz-transform: translateZ(0);
    	-ms-transform: translateZ(0);
    	-o-transform: translateZ(0);
    	transform: translateZ(0);
    	margin-bottom: $space-y*3;
    	.va-container
    	{
    		position: relative;
    		z-index: 50;
    	}
		&:after
		{
			content: ' ';
			display: block;
			margin-top: 100%;
		}
	}
	img
	{
		z-index: 1;
		-moz-transform: scale(1) translateZ(0);
		-webkit-transform: scale(1) translateZ(0);
		-o-transform: scale(1) translateZ(0);
		-ms-transform: scale(1) translateZ(0);
		transform: scale(1) translateZ(0);
		@include transition;
	}
	&__inner
	{
		background: transparentize($brand-primary, .75);
		@include position(absolute, 0 0 0 0);
		z-index: 5;
		&__wrap
		{
			position: relative;
			display: inline-block;
			width: 100%;
		}
		&:before
		{
			background: transparentize($brand-primary, .65);
			@include transition;
			content: ' ';
			border-radius: 50%;
			@include position(absolute, 0 0 0 0);
			-moz-transform: scale(0) translateZ(0);
			-webkit-transform: scale(0) translateZ(0);
			-o-transform: scale(0)  translateZ(0);
			-ms-transform: scale(0) translateZ(0);
			transform: scale(0) translateZ(0);
		}

		.btn
		{
			@include transition;
			opacity: 0;
		}
	}
	&__btn-wrp
	{
		padding-top: 10px;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
	}
	&__image
	{
		@include position(absolute,  0 0 0 0);
		margin: auto;
	}
	h2
	{
		text-align: center;
		font-family: $font-body;
		font-weight: 100;
		text-transform: uppercase;
		b
		{
			color: $brand-primary;
			font-family: $font-heading;
		}
	}
}

.paginate
{
	text-align: center;
	a,
	span
	{
		display: inline-block;
		padding: 7px 12px;
		text-decoration: none;
		border-radius: 5px;
		margin: 0 7px;
	}
	span
	{
		background: $brand-primary;
		border-color: $brand-primary;
		color: #fff;
	}
	a
	{
		color: $brand-primary;
		font-family: $font-regular;
		background: #fafafa;
		border: 1px solid #f1f1f1;
		&:hover
		{
			background: #f1f1f1;
		}
	}
}

.special
{
	margin: $space-y*6 0;
	padding: 50px 60px 60px;
	position: relative;
	-webkit-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		-moz-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		box-shadow: 0 5px 40px rgba(0,0,0,.13);
	background: #fff url(../img/special-btn-bg.png) no-repeat 30px 95%;
	background-size: 350px auto;
	h2
	{
		font-family: $font-body;
		color: $brand-primary;
		margin-top: 0;
		font-weight: 100;
		font-size: 36px;
		margin-bottom: $space-y*3;
	}
	&__bg
	{
		position: absolute;
		top: 0;
		width: 50%;
		bottom: 0;
		right: -6.2%;
		background: url(../../../images/special-bg.png) no-repeat center right;
		background-size: 100% auto;
		z-index: 1;
	}
	&__inner
	{
		position: relative;
		@include clearfix;
		width: 60%;
		min-width: 600px;
		z-index: 2;
		//background: rgba(255,255,255,.5);
	}
	&__form
	{
		width: 100%;
		//float: left;
		padding-right: 330px;
		.btn-wrp
		{
			margin-top: $space-y*2;
			.btn
			{
				border: #000;
				font-weight: bold;
				min-width: 200px;
				padding: 20px;
				&:before
				{
					background: #000;	
				}
			}
		}
	}
	&__text
	{
		width: 280px;
		margin-right: 0;
		float: right;
		font-family: $font-heading;
		text-align: center;
		span,
		b
		{
			display: inline-block;
			width: 100%;
			text-transform: uppercase;
			line-height: 1;
		}
		b
		{
			color: $brand-primary;
		}
		&__big
		{
			font-size: 105px;
		}
		&__small
		{
			font-size: 25px;
		}
		&__normal
		{
			font-size: 55px;
		}
	}

}

.contacts-form
{
	margin: $space-y*6 auto;
	padding: 50px 40px 70px;
	position: relative;
	-webkit-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		-moz-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		box-shadow: 0 5px 40px rgba(0,0,0,.13);
	background: #fff url(../img/special-btn-bg.png) no-repeat 30px 95%;
	background-size: 350px auto;
	max-width: 500px;
	h2
	{
		font-family: $font-body;
		color: $brand-primary;
		margin-top: 0;
		font-weight: 100;
		font-size: 36px;
		margin-bottom: $space-y*3;
	}
	.btn-wrp
	{
		margin-top: $space-y*2;
		.btn
		{
			border: #000;
			font-weight: bold;
			min-width: 200px;
			padding: 20px;
			&:before
			{
				background: #000;	
			}
		}
	}
	

}

.benefits
{
	font-size: 1px;
	padding-bottom: $space-y*8;
	position: relative;
	background: url(../img/curve-bg.png) no-repeat center bottom;
	&__list
	{
		margin-top: $space-y*6;
		background: #f4f4f4;
		position: relative;
		padding: $space-y*4 0;
		&:before
		{
			content: ' ';
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 37px;
			background: url(../img/gray-top.png) center;
		}
	}
	h2
	{
		text-align: center;
		font-family: $font-body;
		font-weight: 100;
		text-transform: uppercase;
		b
		{
			color: $brand-primary;
			font-family: $font-heading;
		}
	}
	&__item
	{
		width: 19%;
		font-size: 14px;
		display: inline-block;
		text-align: center;
		p
		{
			font-size: 18px;
			font-style: italic;
			color: #3b3b3b;
			margin-top: 20px;
			text-transform: uppercase;
		}
	}
	&__icon
	{
		display: inline-block;
		&:before
		{
			display: none !important;
		}
		&__wrap
		{

		}
	}
}

.rus-benefits
{
	background: url(../img/rus-benefits-bg.jpg) no-repeat center;
	background-size: auto 100%;
	h2
	{
		text-align: center;
		font-family: $font-body;
		font-weight: 100;
		text-transform: uppercase;
		b
		{
			color: $brand-primary;
			font-family: $font-heading;
		}
	}
	&__list
	{
		text-align: justify;
		font-size: 1px;
		max-width: 1000px;
		margin: 0 auto;
		&:after
		{
			content: ' ';
			display: inline-block;
			width: 100%;
		}
	}
	&__item
	{
		font-size: $font-size-default;
		display: inline-block;
		width: 232px;
		height: 232px;
		background: url(../img/circle.png) no-repeat center;
		text-align: center;
		padding: 40px;
		h3
		{
			font-family: $font-body;
			font-size: 56px;
			font-style: italic;
			margin-bottom: 10px;
			margin-top: 0;
			img
			{
				max-width: 100%;
			}
		}
		span
		{
			display: inline-block;
			width: 100%;
			font-size: 21px;
			font-family: $font-body;
			font-style: italic;
			font-weight: bold;
		}
		p
		{
			margin: 0;
			margin-top: 10px;
			font-size: 16px;
		}
	}
}


.contacts
{
	font-size: 1px;
	position: relative;
	&__list
	{
		margin-top: $space-y*6;
		background: #f4f4f4;
		position: relative;
		padding: $space-y*2 0;
		text-align: justify;
		font-size: 1px;
		&:after
		{
			content: ' ';
			display: inline-block;
			width: 100%;
		}
		&:before
		{
			content: ' ';
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 37px;
			background: url(../img/gray-top.png) center;
		}
	}
	&__social
	{
		cursor: default;
		a
		{
			display: inline-block;
			width: 46px;
			height: 46px;
			background: $brand-primary;
			line-height: 46px;
			text-align: center;
			font-size: 30px;
			border-radius: 50%;
			color: #fff;
			margin: 0.6vw;
			@include transition;
			&:hover
			{
				background: darken($brand-primary,10%);
			}
		}
	}
	h2
	{
		text-align: center;
		font-family: $font-body;
		font-weight: 100;
		text-transform: uppercase;
		b
		{
			color: $brand-primary;
			font-family: $font-heading;
		}
	}
	&__item
	{
		width: 23%;
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		padding-left: 90px;
		text-align: left;
		position: relative;
		font-size: $font-size-default;
		label
		{
			margin: 0;
			//font-family: $font-regular;
			font-weight: bold;
		}
		p
		{
			margin: 0;
			text-transform: uppercase;
		}

	}
	&__icon
	{
		display: inline-block;
		@include position(absolute, 0 0 0 0);
		margin: auto;
		&__wrap
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 80px;
			bottom: 0;
		}
	}
}

.catalog-viewer
{
	margin-top: $space-y*3;
	height: 90vh;
	position: relative;
	.noUi-tooltip
	{
		white-space: nowrap;
		background: $brand-primary;
		color: #fff;
		border: 0;
	}
	&__paginate
	{
		&__wrap
		{
			position: absolute !important;
			bottom: 0 !important;
			left: 0% !important;
			right: 0% !important;	
			padding: 50px 10% 20px;
			background: #f1f1f1;
			z-index: 150;
			border: 1px solid darken(#f1f1f1, 5%);
		}
		
	}

	&__info
	{

		&__wrap
		{
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			background: lighten($brand-primary, 55%);
			padding: 10px 10%;
			z-index: 150;
			text-align: center;	
		}
		color: #fff;
		display: inline-block;
		background: $brand-primary;
		padding: 5px 10px;
		border-radius: 10px;
		button
		{
			background: none;
			border: 0;
		}
		&__current
		{
			display: inline-block;
			vertical-align: middle;
			&__text
			{
				vertical-align: middle;
				display: inline-block;
				font-size: 13px;
				font-family: $font-regular;
			}
		}
		&__arrow
		{
			vertical-align: middle;
			color: #eee;
			font-size: 18px;
			@include transition;
			&[disabled="disabled"]
			{
				opacity: .3;
			}
			&:hover
			{
				color: #fff;
			}
			&:focus
			{
				outline: 0;
			}
		}
		input
		{
			display: inline-block;
			vertical-align: middle;
			width: 50px;
			border-radius: 10px;
			border: 0;
			padding: 5px 10px;
			border: 0;
			text-align: center;
			&:focus
			{
				outline: 0;
			}
		}

	}
	&__viewport
	{
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		height: 100%;
		text-align: center;
		will-change: transform;
		&.loading
		{
			&:before
			{
				content: ' ';
				@include position(absolute, 0 0 0 0);
				margin: auto;
				display: block;
				width: 66px;
				height: 66px;
				background: url(../img/ajax-loader.gif) no-repeat center;
			}
		}
		&.animate
		{
			@include transition(.3s);
			&.active
			{
				.frwrd &
				{
					@include translate(0);
				}
				.prev &
				{
					@include translate(0);
				}
			}
			&.new
			{
				.frwrd &
				{
					@include translate(-100%);
				}
				.prev &
				{
					@include translate(100%);	
				}	
			}
			
		}
		&.active
		{
			z-index: 20;
			//border: 2px solid red;
			//opacity: .5;
			.frwrd &
			{
				@include translate(100%);
			}
			.prev &
			{
				@include translate(-100%);
			}
		}
		&.new
		{
			z-index: 15;
			//border: 2px solid green;
			.frwrd &
			{
				@include translate(0);
			}
			.prev &
			{
				@include translate(0);	
			}
		}
		&__container
		{
			position: absolute;
			top: 58px;
			left: 10%;
			right: 10%;
			bottom: 75px;
			overflow: hidden;
		}
		
		&__image
		{
			display: inline-block;
			height: 100%;
			position: relative;
			width: auto;
			-moz-transform: translateZ(0);
			-webkit-transform: translateZ(0);
			-o-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			@include transition(.3s);
			
			&.wait-frwrd
			{
				-moz-transform: translateX(150%);
				-webkit-transform: translateX(150%);
				-o-transform: translateX(150%);
				-ms-transform: translateX(150%);
				transform: translateX(150%);
			}
			&.wait-prev
			{
				-moz-transform: translateX(-150%);
				-webkit-transform: translateX(-150%);
				-o-transform: translateX(-150%);
				-ms-transform: translateX(-150%);
				transform: translateX(-150%);
			}
			i
			{
				display: block;
				position: absolute;
				background: #fff;
				z-index: 10;
			}
			i:nth-child(1)
			{
				height: 4%;
				top: 0;
				left: -1%;
				right: -1%;
			}
			i:nth-child(2)
			{
				height: 4%;
				bottom: 0;
				left: -1%;
				right: -1%;
			}
		}
		img
		{
			max-height: 100%;
			width: auto !important;
			-moz-transform: translateZ(0);
			-webkit-transform: translateZ(0);
			-o-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
		}
	}
	&__arrow
	{
		position: absolute;
		top: 0;
		bottom: 0;
		width: 10%;
		background: #fff;
		font-size: 46px;
		color: #777;
		border: 0;
		@include transition;
		z-index: 100;
		&[disabled="disabled"]
		{
			opacity: 0;
		}
		&:focus
		{
			outline: 0;
		}
		&:hover
		{
			color: $brand-primary;
		}
		&.next
		{
			right: 0;
		}
		&.prev
		{
			left: 0;
		}
	}
}

.sweet-alert
{
	z-index: 99999 !important;
}

.agent-form
{
	padding: 50px 50px;
	position: relative;
	margin-top: $space-y*2;
	-webkit-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		-moz-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		box-shadow: 0 5px 40px rgba(0,0,0,.13);
		@include clearfix;
	background: url(../img/right-splash.jpg) no-repeat center right;
	background-size: auto 100%;
	label
	{
		font-family: $font-body;
		text-transform: uppercase;
	}
	&__left
	{
		@include span-columns(6);
	}
	&__right
	{
		@include span-columns(6);	
	}
}

.coordinate-form
{
	padding: 50px 50px;
	position: relative;
	margin-top: $space-y*2;
	-webkit-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		-moz-box-shadow: 0 5px 40px rgba(0,0,0,.13);
		box-shadow: 0 5px 40px rgba(0,0,0,.13);
	display: table;
	width: 100%;
	label
	{
		font-family: $font-body;
		text-transform: uppercase;
	}
	&__left
	{
		@include span-columns(6,table);
		vertical-align: top;
		padding-right: 40px;
	}
	&__right
	{
		@include span-columns(6,table);	
		text-align: center;
		vertical-align: top;
		img
		{
			max-width: 100%;
		}
	}
}