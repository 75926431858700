/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-cart {
  background-image: url(../img/sprite.png);
  background-position: -73px 0px;
  width: 78px;
  height: 64px;
}
.icon-close {
  background-image: url(../img/sprite.png);
  background-position: -213px -150px;
  width: 20px;
  height: 22px;
}
.icon-fabric {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 73px;
  height: 73px;
}
.icon-gift {
  background-image: url(../img/sprite.png);
  background-position: -151px 0px;
  width: 62px;
  height: 66px;
}
.icon-hero-arrow-left {
  background-image: url(../img/sprite.png);
  background-position: -27px -188px;
  width: 27px;
  height: 72px;
}
.icon-hero-arrow-right {
  background-image: url(../img/sprite.png);
  background-position: 0px -188px;
  width: 27px;
  height: 72px;
}
.icon-mail {
  background-image: url(../img/sprite.png);
  background-position: -213px -107px;
  width: 58px;
  height: 43px;
}
.icon-percent {
  background-image: url(../img/sprite.png);
  background-position: -213px -54px;
  width: 51px;
  height: 53px;
}
.icon-phone {
  background-image: url(../img/sprite.png);
  background-position: -66px -73px;
  width: 65px;
  height: 64px;
}
.icon-search {
  background-image: url(../img/sprite.png);
  background-position: -233px -150px;
  width: 16px;
  height: 16px;
}
.icon-star {
  background-image: url(../img/sprite.png);
  background-position: 0px -73px;
  width: 66px;
  height: 64px;
}
.icon-time {
  background-image: url(../img/sprite.png);
  background-position: -213px 0px;
  width: 64px;
  height: 54px;
}
.icon-truck {
  background-image: url(../img/sprite.png);
  background-position: 0px -137px;
  width: 80px;
  height: 51px;
}
